









import { Component, Vue } from 'vue-property-decorator';

import TheFreeSignIcon from '@/components/icons/TheFreeSignIcon.vue';

@Component({
  components: { TheFreeSignIcon },
})
export default class FreeEntryFlag extends Vue {}
