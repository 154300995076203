<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="24"
    viewBox="0 0 32 24"
  >
    <defs>
      <path
        id="yjc6a"
        fill="#363636"
        d="M61.003 485.663c-1.103 0-2 .898-2 2v8c0 1.103.897 2 2 2h24c1.103 0 2-.897 2-2v-8c0-1.102-.897-2-2-2zm24 13.334h-24a3.337 3.337 0 0 1-3.333-3.334v-8a3.337 3.337 0 0 1 3.333-3.333h24a3.337 3.337 0 0 1 3.334 3.333v8a3.337 3.337 0 0 1-3.334 3.334z"
      />
      <path
        id="yjc6b"
        fill="#363636"
        d="M73.003 476.333a2.003 2.003 0 0 0-2.002 2c0 1.103.899 2 2.002 2a2.003 2.003 0 0 0 2.002-2c0-1.102-.898-2-2.002-2zm0 5.334a3.337 3.337 0 0 1-3.333-3.334A3.337 3.337 0 0 1 73.003 475a3.337 3.337 0 0 1 3.334 3.333 3.337 3.337 0 0 1-3.334 3.334z"
      />
      <path
        id="yjc6c"
        fill="#363636"
        d="M63.667 485.669a.667.667 0 0 1-.472-1.137l6.667-6.667a.667.667 0 0 1 .942.943l-6.666 6.666c-.13.13-.3.195-.471.195z"
      />
      <path
        id="yjc6d"
        fill="#363636"
        d="M82.334 485.669a.67.67 0 0 1-.472-.195l-6.667-6.666a.667.667 0 0 1 .943-.943l6.666 6.667a.667.667 0 0 1-.47 1.137z"
      />
      <path
        id="yjc6e"
        fill="#363636"
        d="M81.654 489v1.008h-2.16v1.341h2.07v1.008h-2.07v1.638h2.16v1.008h-3.33V489zm-4.464 0v1.008h-2.16v1.341h2.07v1.008h-2.07v1.638h2.16v1.008h-3.33V489zm-7.326.954v1.809h.335c.227 0 1.16-.027 1.16-.909 0-.891-.924-.9-1.142-.9zm-1.17-.954h1.741c.83 0 1.254.216 1.533.45.46.387.596.9.596 1.35 0 .585-.235 1.098-.704 1.413a1.67 1.67 0 0 1-.658.261l1.876 2.529h-1.461l-1.606-2.412h-.144v2.412h-1.173zm-1.008 0v1.008H65.67v1.341h1.89v1.008h-1.89v2.646H64.5V489z"
      />
    </defs>
    <g>
      <g transform="translate(-57 -475)">
        <g>
          <use xlink:href="#yjc6a"></use>
        </g>
        <g>
          <use xlink:href="#yjc6b"></use>
        </g>
        <g>
          <use xlink:href="#yjc6c"></use>
        </g>
        <g>
          <use xlink:href="#yjc6d"></use>
        </g>
      </g>
      <g transform="translate(-57 -475)">
        <use fill="#363636" xlink:href="#yjc6e"></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheFreeSignIcon',
};
</script>
